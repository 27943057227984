import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl'

// Components
import Container from '../components/Container'
import Footer from '../components/Footer'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

// ================================================================================================

const Legal = ({ intl }) => {
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'legal.title' })} />

      <Container>
        <BackHomeLink to="/">
          <FormattedMessage id="legal.backHome" />
        </BackHomeLink>

        <Heading level="1">
          <FormattedMessage id="legal.title" />
        </Heading>
        <P>
          Pawel Sas
          <br />
          Nagelsweg 22d
          <br />
          20097 Hamburg
        </P>
        <P>
          Telefon: +4953142878328
          <br />
          E-Mail: info@dartsscorer.app
          <br />
        </P>
        <P>
          <b>Hinweis gemäß Online-Streitbeilegungs-Verordnung</b>
        </P>
        <P>
          Nach geltendem Recht sind wir verpflichtet, Verbraucher auf die Existenz der Europäischen
          Online-Streitbeilegungs-Plattform hinzuweisen, die für die Beilegung von Streitigkeiten
          genutzt werden kann, ohne dass ein Gericht eingeschaltet werden muss. Für die Einrichtung
          der Plattform ist die Europäische Kommission zuständig. Die Europäische
          Online-Streitbeilegungs-Plattform ist hier zu finden:{' '}
          <a href="http://ec.europa.eu/odr" target="_blank" rel="noopener noreferrer nofollow">
            http://ec.europa.eu/odr
          </a>
          . Unsere E-Mail lautet: info@dartsscorer.app
        </P>
        <P>
          Wir weisen aber darauf hin, dass wir nicht bereit sind, uns am Streitbeilegungsverfahren
          im Rahmen der Europäischen Online-Streitbeilegungs-Plattform zu beteiligen. Nutzen Sie zur
          Kontaktaufnahme bitte unsere obige E-Mail und Telefonnummer.
        </P>
        <Heading level="2">Disclaimer – rechtliche Hinweise</Heading>
        <Heading level="3">§ 1 Warnhinweis zu Inhalten</Heading>
        <P>
          Die kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden mit größtmöglicher
          Sorgfalt erstellt. Der Anbieter dieser Webseite übernimmt jedoch keine Gewähr für die
          Richtigkeit und Aktualität der bereitgestellten kostenlosen und frei zugänglichen
          journalistischen Ratgeber und Nachrichten. Namentlich gekennzeichnete Beiträge geben die
          Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters wieder. Allein
          durch den Aufruf der kostenlosen und frei zugänglichen Inhalte kommt keinerlei
          Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande, insoweit fehlt es am
          Rechtsbindungswillen des Anbieters.
        </P>
        <Heading level="3">§ 2 Externe Links</Heading>
        <P>
          Diese Website enthält Verknüpfungen zu Websites Dritter ("externe Links"). Diese Websites
          unterliegen der Haftung der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen
          Verknüpfung der externen Links die fremden Inhalte daraufhin überprüft, ob etwaige
          Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße ersichtlich. Der
          Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die
          Inhalte der verknüpften Seiten. Das Setzen von externen Links bedeutet nicht, dass sich
          der Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine
          ständige Kontrolle der externen Links ist für den Anbieter ohne konkrete Hinweise auf
          Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen werden jedoch derartige
          externe Links unverzüglich gelöscht.
        </P>
        <Heading level="3">§ 3 Urheber- und Leistungsschutzrechte</Heading>
        <P>
          Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheber- und
          Leistungsschutzrecht. Jede vom deutschen Urheber- und Leistungsschutzrecht nicht
          zugelassene Verwertung bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder
          jeweiligen Rechteinhabers. Dies gilt insbesondere für Vervielfältigung, Bearbeitung,
          Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder
          anderen elektronischen Medien und Systemen. Inhalte und Rechte Dritter sind dabei als
          solche gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte
          oder kompletter Seiten ist nicht gestattet und strafbar. Lediglich die Herstellung von
          Kopien und Downloads für den persönlichen, privaten und nicht kommerziellen Gebrauch ist
          erlaubt.
        </P>
        <P>
          Die Darstellung dieser Website in fremden Frames ist nur mit schriftlicher Erlaubnis
          zulässig.
        </P>
        <Heading level="3">§ 4 Besondere Nutzungsbedingungen</Heading>
        <P>
          Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den vorgenannten
          Paragraphen abweichen, wird an entsprechender Stelle ausdrücklich darauf hingewiesen. In
          diesem Falle gelten im jeweiligen Einzelfall die besonderen Nutzungsbedingungen.
        </P>
      </Container>

      <Footer />
    </Layout>
  )
}

// ================================================================================================

const BackHomeLink = styled(Link)`
  display: inline-block;
  font-size: 1rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
  text-decoration: none;
`

const Heading = styled.h1`
  font-size: ${({ level }) => (level === '1' ? '2rem' : level === '2' ? '1.5rem' : '1rem')};
  color: var(--text-color);
  margin: ${({ level }) =>
    level === '1' ? '0 0 2rem' : level === '2' ? '2rem 0 1rem 0' : '1rem 0 0.5rem'};

  @media screen and (min-width: 600px) {
    font-size: ${({ level }) => (level === '1' ? '2.5rem' : level === '2' ? '1.75rem' : '1rem')};
  }
`

const P = styled.p`
  font-size: 1rem;
  line-height: 1.4;
  margin-bottom: 1rem;
`

// ================================================================================================

export default injectIntl(Legal)
