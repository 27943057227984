import React from 'react'
import styled from 'styled-components'

// ================================================================================================

const Container = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

// ================================================================================================

export default Container

// ================================================================================================

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 960px;
  padding: 4rem 2rem;
`
